<template>
    <div class="talentRecruitment">
        <div class="top-box">
            <p>人才招聘</p>
        </div>
        
        <div class="content-box">
            <div class="img-box">
                <img src="../assets/image/icon4.jpg" alt="">
            </div>
            <div class="text-box">
               <h1>Java开发工程师</h1>
                <ul>
                    <li class="li2" v-for="(item,i) in list" :key="i">{{item.content}}</li>
                </ul>
            </div>
        </div>
      
    </div>
</template>
<script>
export default {
    data(){
        return{
            list:[
                {
                    content:"1、专科及以上学历；"
                }, {
                    content:"2、具有良好的语言表达能力和人际沟通技巧，具有良好的团队合作精神；"
                }, {
                    content:"3、3年及以上java开发经验，扎实的编程基础，熟悉设计模式，具备项目架构设计能力；"
                }, {
                    content:"4、熟悉spring、springmvc、Springboot SpringCloud框架；"
                }, {
                    content:"5、熟练掌握Ajaxa、JQuery、Extjs等前台技术，精通Flex技术，Fusioncharts等图表展现工具，对页面设计、布局有独到的理解；"
                }, {
                    content:"6、熟练使用oracle、mysql等主流数据库、SQL能力强优先；"
                }, {
                    content:"7、精通webservice、熟悉cxf、axis2等服务框架；"
                }, {
                    content:"8、掌握Springboot SpringCloud等微服务框架，具备分布式架构的研发及实施经验。"
                }
            ]
        }
    }
}
</script>
<style lang="less" scoped>
.talentRecruitment{
    width: 100%;
    height: 100%;
}
.top-box{
    width: 100%;
    height: 80px;
    background-color: #f5f5f5;
    p{
        line-height: 80px;
        font-size: 24px;
        color: #008CBF;
        margin: 0;
        padding: 0 0 0 50px;
    }
}
.content-box{
    margin: 20px 0 0 0;
    display: flex;
}
ul{
    list-style: none;
}
.img-box{
    margin: 30px 0 0 0;
}
.text-box>h1{
    text-align: center;
}
.li1{
    font-size: 1.3rem;
}
.li2{
    font-size: 1.3rem;
    // color: #878787;
    padding: 5px 0 5px 0;
}
</style>